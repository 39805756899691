  .gradient-custom-2 {
    /* fallback for old browsers */
    /*background: blue;*/
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right top, #101828, #475467);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right top, #101828, #475467);
  }
  
  .bet_time {
    position:absolute;
    bottom:5%;
    left:5%;
  }

  @media (min-width: 768px) {
    .gradient-form {
      height: 100vh !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 769px) {
    .gradient-custom-2 {
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }