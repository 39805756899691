  
  .label-align-left{
    text-align: left;
  }

  .bet_time {
    position:absolute;
    bottom:5%;
    left:5%;
  }

  @media (min-width: 768px) {
    .gradient-form {
      height: 100vh !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 769px) {
    .gradient-custom-2 {
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }