.img-logo {
    max-width: 30% !important;
    height: auto;
}

.logo {
    width: 100%;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    font-weight: bold;
}

.text-center {
    text-align: center;
    margin: auto;
}

.rightAlign {
    float: right;
}

.leftAlign {
    float: left;
}

.alignCenter {
    /* Center vertically */
    position: relative;
    top: 40%;
    left: 40%;
    margin: -25px 0 0 -25px; 
    color: black;
}

.alignCenterLeft {
    position: relative;
    top: 50%;
    float: right;
    margin: -15px 10px 0 -15px;
}