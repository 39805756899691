.factorBox {
    background-color: rgb(23,92,211,0.7);
    border: 10px solid #175CD3;
    border-radius: 15px;
    margin: 10px;
}

.factorTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin-top: 1vw;
}

.factorText {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.aiTextStyleSubTitle {
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: blue;
}