.img-logoFooter {
    max-width: 20% !important;
}

.logoFooter {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    font-weight: bold;
    background-color: lightgrey;
}

.text-center {
    text-align: center;
    margin: auto;
}

.rightAlign {
    float: right;
}

.leftAlign {
    float: left;
}

.alignCenterLeftFooter {
    position: relative;
    color: black;
    top: 50%;
    float: right;
    margin: -15px 5vw 0 -15px;
}